import React from 'react'
import { Navbar, Container, Nav, Image } from 'react-bootstrap'

import logo from '../assets/logo.svg'

export default function Navigation() {
  return (
    <Navbar bg="light" variant="light" style={{ maxHeight: '75px' }}>
      <Container>
        <Navbar.Brand href="/">
          <Image
            src={logo}
            width="48"
            height="48"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/">Blog</Nav.Link> */}
            <Nav.Link href="/projects">Projects</Nav.Link>
            <Nav.Link href="/about/">About</Nav.Link>
            <Nav.Link href="/resume">Resume</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
